import {httpProvider} from "@Newfiling/Services";

export const fetchFileComments = (data) => {
    return httpProvider.post('/admincommentlist', data);
};

export const fetchApplicationComments = (data) => {
    return httpProvider.post('/AdminRequestapropertyCommentslist', data);
};

export const fetchJobComments = (data) => {
    return httpProvider.post('/AdminJobsearchCommentsTbs', data);
};

export const readFileComment = (id) => {
    return httpProvider.get('/Adminreadcomment/'+id);
};

export const readApplicationComment = (id) => {
    return httpProvider.get('/AdminReadRequestapropertyComments/'+id);
};

export const readJobComment = (id) => {
    return httpProvider.get('/AdminJobsearchCommentsTbs/'+id);
};

export const deleteFileComment = (id) => {
    return httpProvider.delete('/filedeletecomment/'+id);
};
