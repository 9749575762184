<template>
  <v-dialog v-model="file.modal">
    <v-card>
      <v-card-title class="mb-4 d-flex align-center justify-space-between">
        <div class="d-flex align-center justify-start">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          <div class="text-body-1 font-weight-bold">
            {{ file.title }}
            -
          {{ file.phone1 }}
            -
          {{ file.phone2 }}
            -
          {{ file.phone3 }}
          </div>
        </div>
        <v-btn color="error" @click="file.modal = false" icon fab>
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider/>
      <v-card-text class="pa-6">
        <v-row>
          <v-col :cols='12'>
              <span class="item-name">
               تاریخ :
              </span>
            <span class="item-value">
                {{ file.dateSelection }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
               قیمت کل / رهن :
              </span>
            <span class="item-value">
                {{ file.totalprice }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
               قیمت متری / اجاره :
              </span>
            <span class="item-value">
                {{ file.pricePerMeter }}
              </span>
          </v-col>
        </v-row>
        <v-divider class="my-2"/>
        <v-row>
          <v-col :cols='6'>
              <span class="item-name">
               تعداد طبقه :
              </span>
            <span class="item-value">
                {{ file.countfloors }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
               تعداد واحد :
              </span>
            <span class="item-value">
                {{ file.numberofUnits }}
              </span>
          </v-col>
        </v-row>
        <v-divider class="my-2"/>
        <v-row>
          <v-col :cols='6'>
              <span class="item-name">
               طبقه :
              </span>
            <span class="item-value">
                {{ file.floorNumber }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
                زیربنا :
              </span>
            <span class="item-value">
                {{ file.foundation }}
              </span>
          </v-col>
        </v-row>
        <v-divider class="my-2"/>
        <v-row>
          <v-col :cols='6'>
              <span class="item-name">
               خواب  :
              </span>
            <span class="item-value">
                {{ file.numberOfSleeps }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
               نوع آشپزخانه :
              </span>
            <span class="item-value">
                {{ file.kitchenType }}
              </span>
          </v-col>
        </v-row>
        <v-divider class="my-2"/>
        <v-row>
          <v-col :cols='6'>
              <span class="item-name">
               تلفن ثابت :
              </span>
            <span class="item-value">
                {{ file.telephoneType }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
               سرویس بهداشتی :
              </span>
            <span class="item-value">
                {{ file.wctype }}
              </span>
          </v-col>
        </v-row>
        <v-divider class="my-2"/>
        <v-row>
          <v-col :cols='6'>
              <span class="item-name">
                نوع کف :
              </span>
            <span class="item-value">
                {{ file.floorCoveringType }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
               سال ساخت :
              </span>
            <span class="item-value">
                {{ file.buildingage }}
              </span>
          </v-col>
        </v-row>
        <v-divider class="my-2"/>
        <v-row>
          <v-col :cols='6'>
              <span class="item-name">
               نما :
              </span>
            <span class="item-value">
                {{ file.viewType }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
                نوع سند :
              </span>
            <span class="item-value">
                {{ file.typeofDocument }}
              </span>
          </v-col>
        </v-row>
        <v-divider class="my-2"/>
        <v-row>
          <v-col :cols='6'>
              <span class="item-name">
               مساحت زمین :
              </span>
            <span class="item-value">
                {{ file.landArea }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
               طول بر :
              </span>
            <span class="item-value">
                {{ file.lengthon }}
              </span>
          </v-col>
        </v-row>

        <v-divider class="my-2"/>
        <v-row>
          <v-col :cols='6'>
              <span class="item-name">
               آدرس  :
              </span>
            <span class="item-value">
                {{ file.propertyAddress }}
              </span>
          </v-col>
          <v-col :cols='6'>
              <span class="item-name">
               توضیحات  :
              </span>
            <span class="item-value">
                {{ file.descriptions }}
              </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="w-100 pb-10">
        <div class="d-flex align-center justify-space-between w-100">
          <v-btn
              :loading="fileStatusLoading"
              color="success"
              @click="updateFileStatus(1)"
              v-if="file.filestatus === 2">
            فعال کردن
          </v-btn>
          <v-btn
              :loading="fileStatusLoading"
              color="error"
              @click="updateFileStatus(2)"
              v-if="file.filestatus === 1">
            غیر فعال کردن
          </v-btn>


          <div style="width: 400px" class="d-flex align-center justify-center mr-6">
            <v-select
                outlined
                class="ml-2"
                :items="fileTransactionStatus.items"
                hide-details
                label="وضعیت فایل"
                v-model="fileTransactionStatus.status"
                item-text="name"
                item-value="id"
            />
            <v-btn
                :loading="fileTransactionStatus.loading"
                color="primary"
                @click="updateTransactionStatus"
            >
              اپدیت کردن وضعیت فایل
            </v-btn>
          </div>


        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {updateFileStatus, updateFileTransactionStatus} from "@Newfiling/module-file/src/api";

export default {
  name: "FileDetailsModal",
  data() {
    return {
      fileStatusLoading: false,
      fileTransactionStatus: {
        loading: false,
        status: null,
        items: [
          {
            id: null,
            name: 'هیچ کدام',
          },
          {
            id: 1,
            name: 'اجاره رفته',
          },
          {
            id: 2,
            name: 'فروش رفته',
          },
          {
            id: 3,
            name: 'قیمت تغییر کرده',
          },
          {
            id: 4,
            name: 'منصرف شده',
          },
        ],
      },
    }
  },

  computed: {
    getFileAge() {
      if (this.file.buildingage === 0) {
        return 'نوساز'
      }
      if (this.file.buildingage >= 30) {
        return 'قدیمی'
      }
    }
  },

  props: {
    file: {
      type: Object,
    }
  },
  methods: {
    async updateFileStatus(status) {
      this.fileStatusLoading = true;
      try {
        await updateFileStatus({
          Id: this.file.id,
          Filestatus: status
        });
        const text = `وضعیت فایل تغییر کرد.`
        this.$toast.success(text)
        this.$emit('updateList');
      } catch (e) {
        console.log(e)
      }
      this.fileStatusLoading = false;
    },

    async updateTransactionStatus() {
      this.fileTransactionStatus.loading = true;
      try {
        await updateFileTransactionStatus({
          Id: this.file.id,
          FileTransactiontype: this.fileTransactionStatus.status,
        });
        const text = `وضعیت فایل تغییر کرد.`
        this.$toast.success(text)
        this.$emit('updateList');
      } catch (e) {
        console.log(e)
      }
      this.fileTransactionStatus.loading = false;
    }

  },

  watch: {
    file: {
      handler() {
        this.fileTransactionStatus.status = this.file.fileTransactiontype;
      },
      deep: true,
    }
  }
}
</script>

<style scoped>

</style>