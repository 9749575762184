<template>
  <div class="page-login-desktop">
    <Table
        label="گزارشات فایل ها"
        icon="mdi-format-list-bulleted"
        :table="table"
    >
      <tr
          v-for="(item, index) in table.items"
          :key="index"
      >
        <td class="text-center">{{ index + 1 }}</td>
        <td class="text-center">
          <ImageViewer :src="item.image"/>
        </td>
        <td class="text-center">{{ item.user }}</td>
        <td class="text-center">{{ item.created_at }}</td>
        <td class="text-center">{{ item.title }}</td>
        <td class="text-center">
          <v-chip
              small
              :color="item.status.color"
              text-color="white"
          >
            {{ item.status.name }}
          </v-chip>

          <v-chip
              class="mr-3"
              v-if="item.fileTransaction_type.name"
              small
              :color="item.fileTransaction_type.color"
              text-color="white"
          >
            {{ item.fileTransaction_type.name }}
          </v-chip>

        </td>
        <td class="text-center">{{ item.totalprice }}</td>
        <td class="text-center">{{ item.pricePerMeter }}</td>
        <td class="text-center">

          <v-chip
              x-small
              color="error"
          >
            {{ item.commentCount }}
          </v-chip>
        </td>

        <td class="text-center" style="width: 200px">

          <v-btn
              @click="item.actions.view"
              color="main"
              x-small
              icon
              text
              fab
          >
            <v-icon>
              mdi-file-document
            </v-icon>
          </v-btn>

          <v-btn
              @click="item.actions.edit"
              color="main"
              x-small
              icon
              text
              fab
          >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-btn
              x-small
              @click="item.actions.comment"
              color="warnig"
              icon
              text
              fab
          >
            <v-icon>
              mdi-message-bulleted
            </v-icon>
          </v-btn>

          <v-btn
              @click="item.actions.delete"
              color="error"
              x-small
              icon
              text
              fab
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
          </v-btn>

        </td>
      </tr>
    </Table>

    <Pagination
        v-model="filters.skip"
        :key="filters.skip"
        :totalItems="table.totalCount"
        :perPage="filters.take"
        @change="(page) => { fetchItems({ skip: page }) }"
    />

    <v-dialog
        v-model="deleteItem.modal"
        width="500"
    >
      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این فایل مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="deleteItem.modal = false"
              large
              text
              color="main"
          >
            لغو
          </v-btn>

          <v-btn
              :loading="deleteItem.loading"
              :disabled="deleteItem.loading"
              @click="destroyItem"
              elevation="0"
              large
              color="error"
          >
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <FileDetailsModal
        @updateList="()=>{
          file.modal = false;
          fetchItems();
        }"
        v-model="file.modal"
        :file="file"
    />

    <v-dialog
        max-width="800"
        v-model="comments.modal"
    >
      <v-card>
        <v-card-title class="mb-4 d-flex align-center justify-space-between">
          <div class="d-flex align-center justify-start">
            <v-icon class="ml-2">
              mdi-information-outline
            </v-icon>
            <div class="text-body-1 font-weight-bold">
              لیست گزارشات
            </div>
          </div>
          <v-btn
              color="error"
              @click="comments.modal = false"
              icon
              fab
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-6">
          <v-simple-table v-if="table.items.length !== 0 && !table.loading">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-center table-header">
                  #
                </th>
                <th class="text-center table-header">
                  ارسال کننده
                </th>
                <th class="text-center table-header">
                  تاریخ ثبت
                </th>
                <th class="text-center table-header" style="width: 30%">
                  متن پیام
                </th>
                <th class="text-center table-header">
                  عملیات
                </th>
              </tr>
              </thead>

              <tbody class="table-body">
              <tr
                  v-for="(item, index) in comments.items"
                  :key="index"
              >
                <td class="text-center">{{ index + 1 }}</td>
                <td class="text-center">{{ item.user }}</td>
                <td class="text-center">{{ item.date }}</td>
                <td class="text-center">{{ item.text }}</td>
                <td class="text-center">
                  <v-btn
                      fab
                      icon
                      small
                      color="error"
                      @click="item.actions.delete"
                  >
                    <v-icon>
                      mdi-trash-can-outline
                    </v-icon>
                  </v-btn>
                </td>
              </tr>
              </tbody>

            </template>

          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="commentDeleteItem.modal"
        width="500"
    >

      <v-card>
        <v-card-title class="text-body-1 ">
          <v-icon class="ml-2">
            mdi-information-outline
          </v-icon>
          حذف
        </v-card-title>
        <v-card-text>
          آیا از حذف این نظر مطمئن هستید ؟
        </v-card-text>

        <v-card-actions class="d-flex align-center justify-space-between">
          <v-btn
              @click="commentDeleteItem.modal = false"
              large
              text
              color="main"
          >
            لغو
          </v-btn>

          <v-btn
              :loading="commentDeleteItem.loading"
              :disabled="commentDeleteItem.loading"
              @click="destroyComment"
              elevation="0"
              large
              color="error"
          >
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

  </div>
</template>

<script>
import FileDetailsModal from "@/components/FileDetailsModal";
import Table from "@/components/Table";
import ImageViewer from "@/components/ImageViewer";
import Pagination from "@/components/Pagination";
import {deleteFile, updateFileStatus} from "@Newfiling/module-file/src/api";
import {
  AppConfig,
  getDataFromUrl,
  updateUrlQueries,
} from "@Newfiling/Services";
import {addCommas} from "@persian-tools/persian-tools";
import {
  deleteFileComment,
  fetchFileComments,
  readFileComment,
} from "../../api";

export default {
  name: "DesktopLogin",

  components: {
    FileDetailsModal,
    Table,
    Pagination,
    ImageViewer,
  },

  data() {
    return {
      file: {
        modal: false,
      },
      filters: {
        take: 10,
        skip: 0,
        type: 1,
      },
      table: {
        headers: [
          "#",
          "عکس",
          "تنظیم کننده",
          "تاریخ ثبت",
          "عنوان",
          "وضعیت",
          "قیمت کل / رهن",
          "قیمت متری / اجاره",
          "تعداد کامنت",
          "عملیات",
        ],
        items: [],
        totalCount: 0,
        loading: false,
      },
      deleteItem: {
        modal: false,
        loading: false,
        id: null,
      },

      commentDeleteItem: {
        modal: false,
        loading: false,
        id: null,
      },

      fileTypes: [],
      fileTransactions: [],
      locations: [],
      kitchenTypes: [],
      floorItems: [],
      sleepRoomItems: [],
      telephoneItems: [],
      wcItems: [],
      floorCoverItems: [],
      viewItems: [],
      documentItems: [],
      properties: [],
      comments: {
        modal: false,
        items: [],
      },
    };
  },

  beforeMount() {
    this.$store.commit("Breadcrumb/setBreadcrumb", [
      {
        text: "پنل مدیریت نیوفایل",
        disabled: false,
        href: "/panel/dashboard",
      },
      {
        text: "لیست گزارشات فایل ها",
        disabled: true,
        href: "#",
      },
    ]);
  },

  mounted() {
    this.$nextTick(async () => {
      const appConfig = await AppConfig;
      this.fileTypes = appConfig.PropertyType.map((item) => {
        return {
          id: item.Id,
          name: item.PropertyType,
        };
      });
      this.fileTransactions = appConfig.Typeoftransaction.map((item) => {
        return {
          id: item.Id,
          name: item.Typeoftransaction,
        };
      });
      this.locations = appConfig.neighborhoods.map((item) => {
        return {
          id: item.Id,
          name: item.NeighborhoodName,
        };
      });
      this.kitchenTypes = appConfig.kitchen.map((item) => {
        return {
          id: item.Id,
          name: item.Servicename,
        };
      });
      this.floorItems = appConfig.Floornumber.map((item) => {
        return {
          id: item.Id,
          name: item.FloorName,
        };
      });
      this.sleepRoomItems = appConfig.Numberofsleeps.map((item) => {
        return {
          id: item.Id,
          name: item.Numberofsleeps,
        };
      });
      this.telephoneItems = appConfig.Telephonelinenumber.map((item) => {
        return {
          id: item.Id,
          name: item.Linestatus,
        };
      });
      this.wcItems = appConfig.Wclist.map((item) => {
        return {
          id: item.Id,
          name: item.Servicename,
        };
      });
      this.floorCoverItems = appConfig.FloorCovering.map((item) => {
        return {
          id: item.Id,
          name: item.Servicename,
        };
      });
      this.viewItems = appConfig.ViewType.map((item) => {
        return {
          id: item.Id,
          name: item.Servicename,
        };
      });
      this.documentItems = appConfig.typeofDocument.map((item) => {
        return {
          id: item.Id,
          name: item.Documentname,
        };
      });
      this.properties = appConfig.Features.map((item) => {
        return {
          id: item.Id,
          name: item.Persianname,
        };
      });

      this.filters = {
        ...this.filters,
        ...getDataFromUrl(this.$route),
      };

      await this.fetchItems();
    });
  },

  methods: {
    async fetchItems(filters = {}) {
      this.filters = {
        ...this.filters,
        ...filters,
      };
      this.table.loading = true;

      updateUrlQueries(this.$route.path, this.filters);
      try {
        const res = (
            await fetchFileComments({
              ...this.filters,
              skip: this.filters.skip * this.filters.take,
            })
        )?.data || {
          list: [],
          recordcount: 0,
        };
        const getFileTitle = (file) => {
          let title = "";
          title =
              this.fileTransactions.find((i) => i.id === file.typeOfTransaction)
                  ?.name + " ";
          title =
              title +
              this.fileTypes.find((i) => i.id === file.propertyType)?.name +
              " در ";
          title =
              title + this.locations.find((i) => i.id === file.locationsid)?.name;
          return title;
        };

        const getStatus = (file) => {
          switch (file.filestatus) {
            case 1: {
              return {
                value: file.filestatus,
                name: "فعال",
                color: "success",
              };
            }
            case 2: {
              return {
                value: file.filestatus,
                name: "غیر فعال",
                color: "error",
              };
            }
            default: {
              return {
                value: file.filestatus,
                name: "نا معلوم",
                color: "#333",
              };
            }
          }
        };
        const getFileTransactionStatus = (file) => {
          switch (file.fileTransactiontype) {
            case 1: {
              return {
                name: "اجاره رفته",
                color: "warning",
              };
            }
            case 2: {
              return {
                name: "فروش رفته",
                color: "warning",
              };
            }
            case 3: {
              return {
                name: "قیمت تغییر کرده",
                color: "warning",
              };
            }
            case 4: {
              return {
                name: "منصرف شده",
                color: "warning",
              };
            }
            default: {
              return {
                name: null,
                color: null,
              };
            }
          }
        };

        this.table.items = res?.list.map((item) => {
          return {
            id: item.id,
            image: item.image1 || "/img/placeholder.png",
            user: item.regulatorname || "نا معلوم",
            created_at: item.creationdate.split(" ")[0],
            title: getFileTitle(item),
            totalprice: addCommas(item.totalprice),
            pricePerMeter: addCommas(item.pricePerMeter),
            status: getStatus(item),
            fileTransaction_type: getFileTransactionStatus(item),
            commentCount: item.commentlist.length,
            actions: {
              edit: () => {
                this.$router.push("/panel/files/edit/" + item.id);
              },

              view: () => {
                //readFileComment(item.id);
                this.file = {
                  ...item,
                  id: item.id,
                  modal: true,
                  title: getFileTitle(item),
                  totalprice: addCommas(item.totalprice),
                  pricePerMeter: addCommas(item.pricePerMeter),
                  kitchenType: this.kitchenTypes.find(
                      (i) => i.id === item.kitchenType
                  )?.name,
                  numberOfSleeps: this.sleepRoomItems.find(
                      (i) => i.id === item.numberOfSleeps
                  )?.name,
                  floorNumber: this.floorItems.find(
                      (i) => i.id === item.floorNumber
                  )?.name,
                  telephoneType: this.telephoneItems.find(
                      (i) => i.id === item.phoneStatus
                  )?.name,
                  wctype: this.wcItems.find((i) => i.id === item.wctype)?.name,
                  floorCoveringType: this.floorCoverItems.find(
                      (i) => i.id === item.floorCoveringType
                  )?.name,
                  viewType: this.viewItems.find((i) => i.id === item.viewtype)
                      ?.name,
                  typeofDocument: this.documentItems.find(
                      (i) => i.id === item.typeofDocument
                  )?.name,
                };
              },

              delete: () => {
                this.deleteItem = {
                  ...this.deleteItem,
                  id: item.id,
                  modal: true,
                  loading: false,
                };
              },

              comment: () => {
                const getCommentText = (comment) => {
                  let text = comment.messagetext || "";
                  switch (true) {
                    case comment.thepricehaschanged: {
                      return (text = text + " (قیمت تغییر کرده) ");
                    }
                    case comment.thisfileisrented: {
                      return (text = text + " (فایل اجاره رفته) ");
                    }
                    case comment.canceled: {
                      return (text = text + " (کنسل شده) ");
                    }
                    case comment.thisfileissold: {
                      return (text = text + " (فروش رفته) ");
                    }
                  }

                  return text;
                };
                this.comments = {
                  modal: true,
                  items: item.commentlist.map((i) => {
                    console.log(getCommentText(i));
                    return {
                      ...i,
                      text: getCommentText(i),
                      user: i.sendername + "(" + i.sendermobile + ")",
                      date: i.solarHistory,
                      actions: {
                        delete: () => {
                          this.commentDeleteItem = {
                            ...this.commentDeleteItem,
                            id: item.id,
                            modal: true,
                            loading: false,
                          };
                        },
                      },
                    };
                  }),
                };
              },
            },
          };
        });
        this.table.totalCount = res?.recordcount;
      } catch (e) {
        console.log(e);
      }
      this.table.loading = false;
    },

    async destroyItem() {
      this.deleteItem.loading = true;
      try {
        await deleteFile(this.deleteItem.id);
        this.$toast.success("فایل مورد نظر با موفقیت حذف شد.");
        this.deleteItem = {
          ...this.deleteItem,
          modal: false,
          loading: false,
          id: null,
        };
        await this.fetchItems();
      } catch (e) {
        console.log(e);
      }
      this.deleteItem.loading = false;
    },

    async updateFileStatus(status) {
      if (status.loading) return;
      status.loading = true;
      try {
        await updateFileStatus({
          Id: this.file.id,
          Filestatus: status.id,
        });
        console.log(
            this.table.items[
                this.table.items.findIndex((i) => i.id === this.file.id)
                ]
        );
        this.file.status = status.id;
        this.table.items[
            this.table.items.findIndex((i) => i.id === this.file.id)
            ] = {
          ...this.table.items[
              this.table.items.findIndex((i) => i.id === this.file.id)
              ],
          status,
        };
        const text = `وضعیت فایل به ${status.name}  تغییر کرد. `;
        this.$toast.success(text);
      } catch (e) {
        console.log(e);
      }
      status.loading = false;
    },

    async destroyComment() {
      this.commentDeleteItem.loading = true;
      try {
        await deleteFileComment(this.commentDeleteItem.id);
        this.$toast.success("کامنت مورد نظر با موفقیت حذف شد.");
        this.commentDeleteItem = {
          ...this.commentDeleteItem,
          modal: false,
          loading: false,
          id: null,
        };
        await this.fetchItems();
      } catch (e) {
        console.log(e);
      }
      this.commentDeleteItem.loading = false;
    },
  },
};
</script>
